import React from 'react'
import Helmet from 'react-helmet'
import pic from '../assets/images/sunny-overhead-of-desk_blk.jpg'
import Layout from '../components/layout'
import Contact from '../components/Contact'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Shailaja Vasudevan - Portfolio website"
          meta={[
            { name: 'description', content: 'Shailaja Vasudevan - Portfolio website' },
            { name: 'keywords', content: 'Shailaja Vasudevan,developer,portfolio,designer,javascript,shopify' },
          ]}
        ></Helmet>

        <div id="main">
          <section id="left">
            <article style={{ backgroundImage: `url(${pic})` }}>
              <div className="mainContent">
                <header className="major">
                  <h2>Hello There!</h2>
                  <h4>Thanks for stopping by. This site is COMING SOON. </h4>
                </header>
              </div>
            </article>
            
          </section>

          <Contact />
          
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
