import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <div><h3>CONTACT</h3></div>
                <div>
                    <ul className="icons">
                        <li>
                        <a href="https://github.com/shailv" aria-label="GitHub" className="icon alt fa-github">
                            <span className="label">GitHub</span>
                        </a>
                        </li>
                        <li>
                        <a href="https://www.linkedin.com/in/shailajavasudevan/" aria-label="LinkedIn" className="icon alt fa-linkedin">
                            <span className="label">LinkedIn</span>
                        </a>
                        </li>
                        <li>
                        <a href="https://twitter.com/_shailv" aria-label="Twitter" className="icon alt fa-twitter">
                            <span className="label">Twitter</span>
                        </a>
                        </li>
                    </ul>
                </div>

                <form name="contactMe" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                    <input type="hidden" name="bot-field" />
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" required/>
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" required/>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6" required></textarea>
                    </div>
                    <div className="field">
                        <input type="submit" value="Submit" className="special" />
                    </div>
                </form>
                
            </section>
            
        </div>
    </section>
)

export default Contact
